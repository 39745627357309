<template>
  <div id="support-page">
    <Breadcrumb />
    <main>
      <h1>{{ $t("SupportPage.Title") }}</h1>
      <h5>{{ $t("SupportPage.Subtitle") }}</h5>
      <div class="cards-container">
        <Card
          :title="$t('SupportPage.Email')"
          :subtitle="$t('SupportPage.SubtitleTeamHelp')"
          :text-to-clipboard="info.email.value"
          :tooltip="{
            copy: $t('SupportPage.CopyEmail'),
            copied: $t('SupportPage.Copied'),
          }"
          :toast="{
            title: $t('SupportPage.EmailCopied'),
          }"
        >
          <template #icon>
            <EmailIcon />
          </template>
        </Card>
        <Card
          title="WhatsApp"
          :subtitle="$t('SupportPage.OperationHour')"
          :text-to-clipboard="info.whatsapp.value"
          :tooltip="{
            copy: $t('SupportPage.CopyNumber'),
            copied: $t('SupportPage.Copied'),
          }"
          :toast="{
            title: $t('SupportPage.NumberCopied'),
          }"
          type="phone"
        >
          <template #icon>
            <WhatsappIcon />
          </template>
        </Card>
      </div>
    </main>
  </div>
</template>

<script>
import EmailIcon from "@/assets/images/icons/email.svg";
import WhatsappIcon from "@/assets/images/icons/whatsapp-icon-white.svg";
import { mapMutations } from "vuex";
import Breadcrumb from "../../../layouts/components/Breadcrumb.vue";
import Card from "./Card.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    WhatsappIcon,
    EmailIcon,
  },

  props: {},
  data() {
    return {
      info: {
        whatsapp: {
          value: "+55 27 99640-5558",
        },
        email: {
          value: "support@troika.ind.br",
        },
      },
    };
  },
  mounted() {
    this.UPDATE_FLAG_SHOW_OVERLAY(false);
  },
  methods: {
    ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
  },
};
</script>

<style lang="scss">
#support-page > main {
  background-color: #362f2b;
  background-image: url("../../../assets/images/icons/cubos.png");
  background-size: 175px;
  padding: 131px 29px;
  width: calc(100vw + 5px);
  margin-left: -20px;
  min-height: calc(100vh - 178px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#support-page > main {
  h1,
  h5 {
    font-family: "Raleway";
    font-style: normal;
    color: white;
    text-align: center;
  }

  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
  }
  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 30px;
    padding-top: 24px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  #support-page > main {
    padding: 40px 29px !important;
  }
}
@media (max-width: 480px) {
  #support-page > main {
    padding: 40px 16px !important;
    h1 {
      font-size: 22px !important;
      line-height: 30px !important;
    }

    h2 {
      font-size: 16px !important;
      line-height: 26px !important;
    }
  }
}
</style>
